import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy
} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, Validators} from '@angular/forms';
import {County} from '@ee/common/models';
import {CountyService} from '@ee/common/services';
import {STATES} from '@ee/common/constants';
import {firstValueFrom, Subscription} from 'rxjs';
import forEach from 'lodash-es/forEach';

@Component({
  selector: 'ee-county-overrides',
  template: `
    <div class="flex flex-col">
      <div class="mb-4" *ngIf="countyOverrideRules?.length; else noOverrides">

        <div class="flex flex-row justify-center items-baseline">
          <b class="label text-center mb-2">
            Predefined County Override Rules
          </b>
        </div>
        <table class="county-table w-full">
          <thead>
            <th style="width: 20%">State</th>
            <th style="width: 20%">Zip Code</th>
            <th style="width: 30%">County Name</th>
            <th></th>
          </thead>
          <tr *ngFor="let rule of countyOverrideRules.controls; let i = index">
            <td>{{rule.get('state').value}}</td>
            <td>{{rule.get('zip_code').value}}</td>
            <td>{{addedCounties[rule.get('county_id').value]?.name ?? 'Unknown County'}}</td>
            <td><button mat-button type="button" (click)="removeRule(i)">Remove</button></td>
          </tr>
        </table>
      </div>
      <div class="flex flex-col justify-start items-center border border-solid border-black p-2">
        <p class="w-full text-center">To start, search for counties by state and zip code. Then select a county and add the rule.</p>
        <div class="w-full flex flex-row justify-start items-baseline">
          <div class="w-1/2 flex flex-row justify-start items-start">
            <mat-form-field class="compact w-1/3 mr-2">
              <mat-label>State</mat-label>
              <mat-select required [(ngModel)]="selectedState">
                <mat-option *ngFor="let state of states | keyvalue" [value]="state.key">
                  {{state.value}}
                </mat-option>
              </mat-select>
              <mat-hint>
                Required
              </mat-hint>
            </mat-form-field>
            <mat-form-field class="compact w-1/3 mr-2">
              <mat-label>Zip Code</mat-label>
              <input matInput mask="00000" [minLength]="5" [maxLength]="5" [(ngModel)]="selectedZipCode" required>
              <mat-hint>Required</mat-hint>
            </mat-form-field>
            <button mat-button type="button" [disabled]="!selectedState || !selectedZipCode" (click)="search()">
              Search
            </button>
          </div>
          <div class="w-1/2 flex flex-row justify-end items-start">
            <mat-form-field class="compact w-2/3 mr-2">
              <mat-label>County</mat-label>
              <mat-select [(ngModel)]="selectedCountyId"
                          [disabled]="!selectedState || !selectedZipCode || !counties.length">
                <mat-option>-- None --</mat-option>
                <mat-option *ngFor="let county of counties" [value]="county.id">
                  {{county.name}}
                </mat-option>
              </mat-select>
              <mat-hint>Optional</mat-hint>
            </mat-form-field>
            <button mat-flat-button type="button" color="primary" [disabled]="!selectedCountyId" (click)="save()">
              Save Rule
            </button>
          </div>
        </div>
      </div>
      <ng-template #noOverrides>
        <b class="label text-center">
          No county override rules have been defined.
        </b>
      </ng-template>
      <!--      <div>-->
      <!--        <button mat-flat-button cdkFocusInitial color="accent" [disabled]="form.invalid" type="submit">-->
      <!--          Save-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  `,
  styles: [`
    table.county-table, table.county-table th, table.county-table td {
      border: 1px solid #ececec;
      text-align: center;
    }
    b.label {
      font-size: 1rem;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CountyOverridesComponent implements OnChanges, OnDestroy {
  @Input() countyOverrideRules: UntypedFormArray;

  counties: County[] = [];
  selectedZipCode: string;
  selectedState: string;
  selectedCountyId: string | undefined;
  states = STATES;
  addedCounties: {string?: County} = {};
  private subs: Subscription[] = [];

  constructor(private fb: UntypedFormBuilder,
              private countyService: CountyService,
              private change: ChangeDetectorRef) {
  }

  ngOnChanges(): void {
    if (this.countyOverrideRules?.length) {
      this.countyOverrideRules.value.forEach((rule: any) => {
        this.subs.push(this.countyService.getCountyById(rule.county_id)
          .subscribe((county) => {
            this.addedCounties[county.id] = county;
            this.change.markForCheck();
          }));
      });
    }
  }

  search(): void {
    this.counties = [];
    this.selectedCountyId = undefined;
    this.subs.push(this.countyService.getCountiesByState(this.selectedState)
      .subscribe((counties) => {
        this.counties = [];

        forEach(counties, (county: County) => {
          const zip = Number(this.selectedZipCode);
          if (zip > 0 ? county.zip_codes.indexOf(zip) > -1 : false) {
            this.counties.unshift(county);
            this.selectedCountyId = county.id;
          } else {
            this.counties.push(county);
          }
        });
        this.change.markForCheck();
      }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  save(): void {
    let updated = false;
    // first check if rule already exists and override it if it does
    this.countyOverrideRules.value.forEach((rule: any, index: number) => {
      if (rule.state === this.selectedState && rule.zip_code === Number(this.selectedZipCode)) {
        this.countyOverrideRules.at(index)?.patchValue({county_id: this.selectedCountyId});
        updated = true;
      }
    });
    // if not, add a new rule
    if (!updated) {
      this.countyOverrideRules.push(this.fb.group({
        state: [{value: this.selectedState, disabled: true}, Validators.required],
        zip_code: [{value: Number(this.selectedZipCode), disabled: true}, Validators.required],
        county_id: [{value: this.selectedCountyId, disabled: true}, Validators.required]
      }));
    }

    this.addedCounties[this.selectedCountyId] = this.counties.find(c => c.id === this.selectedCountyId) ;
    this.selectedState = '';
    this.selectedZipCode = '';
    this.selectedCountyId = '';
    this.counties = [];
    this.change.markForCheck();
  }

  async getCountyName(countyId: string): Promise<string> {
    const county = await firstValueFrom(this.countyService.getCountyById(countyId));
    return county?.name || 'Unknown';
  }

  removeRule(index: number): void {
    this.countyOverrideRules.removeAt(index);
  }

}
